import * as React from "react";
import { Helmet } from "react-helmet-async";

import * as styles from "../styles/About.module.scss";
import Layout from "../component/Layout";
import AccordionItem from "../component/AccordionItem/AccordionItem";
// import AccordionItem from '../component/AccordionItem'

// markup
const About = ({ location }) => {
  const params = new URLSearchParams(location.search);

  const show = params.get("show");

  const data = [
    {
      title: "How the project was born?",
      text: "Hey, Reds! We are super excited to announce the launch of the Metaverse dedicated to the fans of the world’s best football club! We have been Liverpool supporters for decades. We’ve also been following the blockchain technology since 2016. With its advance it has become possible to own digital assets (such as pictures, videos, music or items in computer games) through NFTs. In its turn, digital assets began to be actively used as internal objects in the Metaverse. Entire Internet communities began to form around the ownership of NFTs from the same collection or publisher. Finally, in 2021 we decided to combine our knowledge and passion. That’s how the Reds Club was born.",
      isShow: false,
      id: "1",
    },
    {
      title: "What is LFC fans Metaverse?",
      text: "After Facebook was renamed into Meta in 2021, the hype around this word was only increasing. But what is Metaverse? At the moment there is no specific definition, but Wikipedia says that Metaverse is a network of virtual worlds focused on social connection. Speaking about the Metaverse that we build - it will be a global independent digital community of Liverpool FC fans with its own digital assets as NFTs.",
      isShow: false,
      id: "2",
    },
    {
      title: "What is NFT card?",
      text: "NFTs (Non-Fungible Tokens) are cryptographic assets on a blockchain with unique identification codes and metadata that distinguish them from each other. NFTs can be anything digital - pictures, music, videos, etc. The first digital assets we use in our Metaverse are NFT artwork cards. There are two types of them: membership cards and collectible cards. Membership card is a NFT that gives its owner the right to participate in all Club’s activities during current football season (something like membership or season ticket in sports). Collectible card is a NFT that represents work of art created by community members (something like paper trading cards in sports). Membership cards can be purchased from the Club, but collectible cards can be only received by active Club’s members as a prize.",
      isShow: false,
      id: "3",
    },
    {
      title: "How to become Reds Club member?",
      text: "We are sensitive to ensure that only true Liverpool fans are in the Club, that’s why before being able to purchase an NFT, each candidate must complete a short survey. After being approved you will have a chance to mint your personal membership NFT Reds Club card. Owning Red Club’s personal NFT gives you voting rights and includes access to Club’s private chats, competitions, events, collectible NFT cards drawings, merchandise distribution and more.",
      isShow: false,
      id: "4",
    },
    {
      title: "Can I trade NFT cards?",
      text: "As we have already mentioned, the Reds Club is a place for Liverpool FC fans only and every candidate need to be approved before he can mint membership NFT card. That’s why this type of cards are personal and non-transferable. As for collectible cards - they are created by the community members, so it is up to owners if they want to transfer them to others or not. The only thing we want to be sure is that card’s new owner is a Liverpool supporter too. All actions with tokens should be carried out exclusively among Liverpool fans. Also, pay attention that any cards are intended for use in the Reds Club Metaverse for entertainment, and should not be considered as an investment instrument.",
      isShow: false,
      id: "5",
    },
  ];

  const [list, setList] = React.useState(data);

  React.useEffect(() => {
    toggleIsShow(show);
  }, []);

  const toggleIsShow = (id: string) => {
    const activeItem = list.find((item) => item.id === id);

    const resetList = list.map((item) => {
      return { ...item, isShow: false };
    });

    const newList = resetList.map((item) => {
      if (item.id === id) {
        item.isShow = !activeItem?.isShow;
      }

      return item;
    });

    setList(newList);
  };
  return (
    <>
      <Helmet>
        <title> About - Reds Club</title>
        <meta property='og:title' content='About Reds Club' />
        <meta name='description' content='About Reds Club ' />
        <meta property='og:description' content='About Reds Club' />
      </Helmet>
      <Layout>
        <main>
          <section className={styles.about}>
            <div className={styles.title}>About / FAQ</div>
            <div className={styles.accordionSection}>
              {list.map((item, i) => {
                return (
                  <AccordionItem
                    id={item.id}
                    toggleIsShow={toggleIsShow}
                    isShow={item.isShow}
                    title={item.title}
                    text={item.text}
                    key={i}
                  />
                );
              })}
            </div>
          </section>
        </main>
      </Layout>
    </>
  );
};

export default About;
