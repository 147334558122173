import React, { useState } from 'react'
import * as styles from './AccordionItem.module.scss'
import icon from '../../images/about/tringularFAQ.svg'

const AccordionItem = ({
  title,
  text,
  isShow,
  toggleIsShow,
  id,
}: {
  title: string
  text: string
  isShow: boolean
  id: string
  toggleIsShow: (id: string) => void
}) => {
  // const contentStyles =['content']
  // if(isShow){
  //   push
  // }

  return (
    <div id={id} className={styles.accordionItem} onClick={() => toggleIsShow(id)}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <img
          className={`${styles.icon} ${isShow ? styles.rotate : ''}`}
          src={icon}
          alt='triangular'
        />
      </div>
      <div className={`${styles.content} ${isShow ? styles.animated : ''} `}>{text}</div>
    </div>
  )
}

export default AccordionItem
